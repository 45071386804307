import * as React from "react"
import { PropsWithChildren, ReactChildren, ReactNode } from "react"

const TheContainer = ({ children, className }: { children?: ReactNode; className?: string}) => (
  <div
    className={`theContainer ${className}`}
  >
    { children }
  </div>
)

export default TheContainer
