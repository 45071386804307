import * as React from "react"
import TheContainer from "./TheContainer"
import MenuIcon from '../icons/menu.svg'
import XIcon from '../icons/x.svg'
import { StaticImage } from "gatsby-plugin-image"
import { useState } from "react"

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  return (
    <div
      className='fixed top-0 left-0 right-0 z-40'
    >
      <nav
        className='
          bg-white border-b border-gray-200 px-4 py-4 text-gray-800
        '
      >
        <TheContainer
          className='flex items-center justify-between'
        >
          <div>
            <StaticImage
              src="../images/logo.png"
              loading='eager'
              width={140}
              fit='contain'
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              objectFit='contain'
              alt="Neovis logo"
            />
          </div>
          <div
            className='hidden sm:block'
          >
            <a
              href='#'
              className='
                mr-6 hover:opacity-75 duration-100 focus:ring-2 focus:ring-blue-500
                rounded px-1
              '
            >
              <b>Strona główna</b>
            </a>
            <a
              href='#o-nas'
              className='
                mr-6 hover:opacity-75 duration-100 focus:ring-2 focus:ring-blue-500
                rounded px-1
              '
            >
              <b>O nas</b>
            </a>
            <a
              href='#kontakt'
              className='
                hover:opacity-75 duration-100 focus:ring-2 focus:ring-blue-500
                rounded px-1
              '
            >
              <b>Kontakt</b>
            </a>
          </div>
          <div
            className='flex items-center justify-center sm:hidden'
          >
            {menuOpen ?
              <button
                className='
                  p-px hover:opacity-75 duration-100
                  focus:ring-2 focus:ring-blue-500 rounded
                '
                onClick={() => setMenuOpen(false)}
              >
                <XIcon
                  stroke='#040404'
                />
              </button>
              :
              <button
                className='
                  p-px hover:opacity-75 duration-100
                  focus:ring-2 focus:ring-blue-500 rounded
                '
                onClick={() => setMenuOpen(true)}
              >
                <MenuIcon
                  stroke='#040404'
                />
              </button>
            }
          </div>
        </TheContainer>
      </nav>
      {menuOpen &&
        <div
          className='absolute top-0 left-0 right-0 w-full h-screen z-40'
          style={{
            backgroundColor: 'rgba(0, 0, 0, .3)'
          }}
        >
          <nav
            className='
              bg-white border-b border-gray-200 px-4 py-4 text-gray-800
            '
          >
            <TheContainer
              className='flex items-center justify-between'
            >
              <div>
                <StaticImage
                  src="../images/logo.png"
                  loading='eager'
                  width={140}
                  fit='contain'
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  objectFit='contain'
                  alt="Neovis logo"
                />
              </div>
              <div
                className='flex items-center justify-center'
              >
                <button
                  className='
                    p-px hover:opacity-75 duration-100
                    focus:ring-2 focus:ring-blue-500 rounded
                  '
                  onClick={() => setMenuOpen(false)}
                >
                  <XIcon
                    stroke='#040404'
                  />
                </button>
              </div>
            </TheContainer>
          </nav>
          <nav
            className='
              z-40 fixed left-0 right-0 sm:hidden
              w-full bg-white px-4 border-b border-gray-200
            '
          >
            <TheContainer>
              <a
                href='#o-nas'
                className='
                my-4 py-3 w-full flex items-center justify-center rounded
                hover:opacity-75 focus:ring-2 focus:ring-blue-500 duration-100
              '
              >
                <b>O Nas</b>
              </a>
              <a
                href='#kontakt'
                className='
                my-4 py-3 w-full flex items-center justify-center rounded
                hover:opacity-75 focus:ring-2 focus:ring-blue-500 duration-100
              '
              >
                <b>Kontakt</b>
              </a>
            </TheContainer>
          </nav>
        </div>
      }
    </div>
  )
}

export default Navbar
